<template>
  <div
    class="w-full flex grow items-center justify-center light-mode"
  >
    <div class="w-full my-3 px-5 lg:w-1/2">
      <panel
        v-if="step === 1"
        class="text-center p-16 w-full flex flex-col items-center"
      >
        <div class="w-full">
          <div>
            <h5 class="mb-5 font-bold text-platform-text-color text-5xl text-center md:mb-5 leading-tight">
              <template>
                {{ 'components/guest/register.title' | trans }}
              </template>
            </h5>
          </div>

          <form
            method="post"
            @submit.prevent="onSubmit"
          >
            <div class="my-2">
              <div class="relative flex">
                <input
                  id="capture"
                  v-model="form.capture"
                  type="text"
                  name="capture"
                  class="capture"
                  style="display: none;"
                >

                <input
                  id="email"
                  v-model="form.email"
                  type="email"
                  name="email"
                  :disabled="emailDisabled"
                  :placeholder="translate('register.email-placeholder')"
                  aria-label="Email"
                  class="appearance-none ml-0 w-full mr-[28px] py-2 pr-4 bg-transparent border-2 border-platform-border-color focus:border-labels-yellow text-platform-text-color rounded font-light focus:outline-none"
                  :class="isGoogleUser ? 'pl-10' : 'pl-4'"
                >
                <div
                  v-if="isGoogleUser"
                  class="lg:text-xl absolute top-0 pt-3 pb-2 pl-4"
                >
                  <img
                    src="/images/google-sso/google_g_mark.png"
                    height="18"
                    width="18"
                  >
                </div>
              </div>
            </div>


            <div
              v-if="errors.has('email')"
              class="mr-[28px]"
            >
              <div class="px-3 mt-1 rounded text-base text-labels-warning text-center">
                <span v-html="errors.first('email')" />
              </div>
            </div>

            <div v-if="isManual">
              <template v-if="!ssoUser">
                <div
                  class="flex my-2 relative"
                >
                  <input
                    id="password"
                    v-model="form.password"
                    type="password"
                    name="password"
                    :placeholder="translate('register.password-placeholder')"
                    aria-label="password"
                    class="appearance-none my-auto w-full py-2 px-4 bg-transparent border-2 border-platform-border-color focus:border-labels-yellow text-platform-text-color rounded font-light focus:outline-none "
                    @input="errors.remove('password')"
                    @blur="validateConfirmationPassword"
                  >
                  <button
                    type="button"
                    class="relative w-[28px]"
                    tabindex="-1"
                    @click="openPasswordRules"
                  >
                    <i class="fas fa-info-circle fa-lg ml-2 text-platform-icon" />
                  </button>
                  <modal
                    :show="showPasswordRules"
                    :show-cross="true"
                    @close-modal="closePasswordRules"
                  >
                    <template #title>
                      <i class="fa-solid fa-circle-info text-button-secondary mr-1" />
                      {{ 'register.password-rules-title' | trans }}
                    </template>
                    <div class="p-2 text-left">
                      <p>{{ 'register.password-rules' | trans }}</p>
                    </div>
                  </modal>
                </div>
                <div
                  class="flex mt-2"
                >
                  <input
                    id="password-confirmation"
                    v-model="form.passwordConfirmation"
                    type="password"
                    name="password_confirmation"
                    aria-label="Confirm password"
                    :placeholder="translate('register.password-confirmation-placeholder')"
                    class="appearance-none w-full py-2 px-4 bg-transparent border-2 border-platform-border-color focus:border-labels-yellow text-platform-text-color rounded font-light focus:outline-none "
                    @blur="validateConfirmationPassword"
                    @input="errors.remove('password_confirmation')"
                  >
                  <div
                    type="button"
                    class="w-[28px]"
                  >
                    <i
                      v-show="passwordConfirmed"
                      class="fas fa-check-circle fa-lg ml-2 text-labels-success"
                    />
                  </div>
                </div>
                <div
                  v-if="errors.has('password')"
                  class=""
                >
                  <div class="rounded text-labels-warning ml-2 text-left">
                    {{ errors.first('password') }}
                  </div>
                </div>
              </template>

              <div class="flex my-2">
                <input
                  id="first-name"
                  v-model="form.firstName"
                  type="text"
                  name="first_name"
                  aria-label="First name"
                  :placeholder="translate('register.first-name-placeholder')"
                  class="appearance-none w-full mr-[28px] py-2 px-4 bg-transparent border-2 border-platform-border-color focus:border-labels-yellow text-platform-text-color rounded font-light focus:outline-none "
                  @input="errors.remove('first_name')"
                  @blur="errors.remove('first_name')"
                >
                <div
                  v-if="errors.has('first_name')"
                  class=""
                >
                  <div class="pb-3 mb-1 rounded text-labels-warning text-left">
                    {{ errors.first('first_name') }}
                  </div>
                </div>
              </div>
              <div class="flex my-2">
                <input
                  id="last-name"
                  v-model="form.lastName"
                  type="text"
                  name="last_name"
                  aria-label="Last name"
                  :placeholder="translate('register.last-name-placeholder')"
                  class="appearance-none w-full mr-[28px] py-2 px-4 bg-transparent border-2 border-platform-border-color focus:border-labels-yellow text-platform-text-color rounded font-light focus:outline-none "
                  @input="errors.remove('last_name')"
                  @blur="errors.remove('first_name')"
                >
              </div>
              <div
                v-if="errors.has('last_name')"
                class=""
              >
                <div class="pb-3 mb-1 rounded text-labels-warning text-left">
                  {{ errors.first('last_name') }}
                </div>
              </div>
              <div>
                <check-box
                  id="terms"
                  class="mb-2 mt-5 mx-auto"
                  name="terms"
                  checkbox-class="is-blue"
                  @checkbox-changed="setTerms"
                >
                  <template #label>
                    <label
                      for="terms"
                      class="font-body text-platform-text-color text-base tracking-tight"
                    >
                      {{ 'register.terms-and-conditions' | trans }} <a
                        class="underline"
                        href="https://www.nimbushosting.co.uk/docs/terms.pdf"
                        target="_blank"
                      >Terms of
                        Service</a>
                    </label>
                  </template>
                </check-box>
                <div
                  v-if="errors.has('accepted_terms')"
                  class=""
                >
                  <div class="mb-1 rounded text-labels-warning text-left">
                    {{ errors.first('accepted_terms') }}
                  </div>
                </div>
              </div>
              <div class="my-2">
                <check-box
                  id="marketing"
                  class="mb-2"
                  name="marketing"
                  checkbox-class="is-blue"
                  @checkbox-changed="setMarketing"
                >
                  <template #label>
                    <label
                      for="marketing"
                      class="font-body text-platform-text-color text-base tracking-tight my-auto"
                    >
                      {{ 'register.marketing-opt-in' | trans }}
                    </label>
                  </template>
                </check-box>
              </div>
            </div>
            <div class="mt-10 mx-auto text-center">
              <button
                type="submit"
                class="button-purchase disabled:bg-platform-border-color disabled:border-platform-border-color w-2/3 disabled:cursor-not-allowed"
                :disabled="isLoading || !isSubmittable"
              >
                <div
                  v-if="isLoading"
                  class="flex"
                >
                  <tiny-loader class="mr-4" />
                  <span>{{ 'register.creating-button' | trans }}</span>
                </div>
                <span v-else>{{ 'register.submit-button' | trans }}</span>
              </button>
            </div>
          </form>
          <google-sso-button v-if="ssoProviders.google && !ssoUser" />
        </div>
      </panel>
    </div>
  </div>
</template>

<script>
import Error from '../Error';

import CheckBox from "@ComponentsV2/Global/CheckBox.vue";
import Modal from "@ComponentsV2/Global/Modal.vue";
import GoogleSsoButton from "@/Pages/Auth/Login/GoogleSsoButton.vue";
import {ExtractApiError} from "@/Helpers/ApiHelpers";
import TinyLoader from "../../../Components/Global/loaders/TinyLoader.vue";

export default {
  components: {GoogleSsoButton, Modal, CheckBox, TinyLoader},
  props: {
    ssoProviders: Object,
    ssoUser: {
      type: [Object, null],
      required: false,
    },
    userEmail: {
      type: String,
      required: false,
    },
    userEmailHash: {
      type: String,
      required: false,
      default: null
    },
    recaptchaSiteKey: {
      type: String
    }
  },
  data () {
    return {
      form: {
        email: '',
        emailHash: '',
        capture: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
        terms: false,
        marketing: false,
        accepted_terms: false,
      },

      isGoogleUser: false,
      step: 1,
      errors: new Error(),
      isLoading: false,
      passwordConfirmed: false,
      showPasswordRules: false,
      resetError: null,
      emailDisabled: false
    };
  },
  computed: {
    isTrial () {
      let isTrial = false;

      const urlParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries());

      if (typeof urlParams !== 'undefined' && 1 == urlParams.trial) {
        isTrial = true;
      }

      return isTrial;
    },
    isSubmittable () {
      if (!this.form.email.length) {
        return false;
      }

      if (!this.form.firstName.length) {
        return false;
      }

      if (!this.form.lastName.length) {
        return false;
      }

      if (!this.ssoUser) {
        if (!this.form.password.length) {
          return false;
        }

        if (!this.form.passwordConfirmation.length) {
          return false;
        }
      }

      return this.form.terms;
    },
    isManual () {
      if (this.form.email.length === 0) {
        return false
      }
      return true;
    }
  },
  mounted () {
    if (this.ssoUser) {
      this.form.email = this.ssoUser?.email ?? ''
      this.form.firstName = this.ssoUser?.first_name ?? ''
      this.form.lastName = this.ssoUser?.last_name ?? ''
      this.isGoogleUser = true
      this.disableEmailInput();
    } else if (this.userEmail) {
      this.form.email = this.userEmail
      this.form.emailHash = this.userEmailHash
      this.disableEmailInput();
    }
  },
  methods: {
    nextStep (redirectTo) {
      window.location = redirectTo
    },
    async resetForm () {
      try {
        await this.$http.delete('/sign-up')
        window.location = '/sign-up'
      } catch (e) {
        this.resetError = ExtractApiError(e)
      }
    },
    validateConfirmationPassword () {
      if (this.form.password.length < 8 && this.form.passwordConfirmation.length < 8) {
        this.errors.set({
          password: [this.translate('components/auth/register.error.password-length')]
        });
        setTimeout(() => {
          this.errors.remove('password');
        }, 2000);

        this.passwordConfirmed = false

      } else if (this.form.passwordConfirmation && this.form.password !== this.form.passwordConfirmation) {
        this.errors.set({
          password: [this.translate('components/auth/register.error.password-match')]
        });
        setTimeout(() => {
          this.errors.remove('password');
        }, 2000);

        this.passwordConfirmed = false

      } else {
        this.errors.set({
          password: []
        });

        if (this.form.password === this.form.passwordConfirmation) {
          this.passwordConfirmed = true
        }
      }
    },
    openPasswordRules () {
      this.showPasswordRules = true
    },
    closePasswordRules () {
      this.showPasswordRules = false
    },
    setTerms (payload) {
      this.form.terms = payload;
      if (payload) {
        this.errors.remove('accepted_terms');
      }
    },
    setMarketing (payload) {
      this.form.marketing = payload;
    },
    signUp (token) {
      return this.$http.post('/sign-up', {
        email: this.form.email,
        emailHash: this.form.emailHash,
        capture: this.form.capture,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
        accepted_terms: this.form.terms,
        accepted_marketing: this.form.marketing,
        trial: this.isTrial,
        recaptcha_token: token

      }).then(response => {
        this.nextStep(response.data.redirect_to)
      })
        .catch(errors => {
          this.errors.set(errors.response.data.errors)
        })
    },
    reset () {

    },
    generateRecaptchaToken () {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(this.recaptchaSiteKey, {action: 'register'})
            .then(function (token) {
              resolve(token)
            }).catch(function (error) {
              reject(error)
            });
        });
      });

    },
    async onSubmit () {
      this.isLoading = false
      try {
        await this.generateRecaptchaToken().then((token) => {
          this.signUp(token)
        })
      } finally {
        this.isLoading = false
      }
    },
    disableEmailInput () {
      this.emailDisabled = true
    },
  }
};
</script>

<style>
input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
